//此页面是存放api的url地址的
//主页地址
export const apiList = {
  login: "/login/",
  loginout: "/loginout/",
  Gate: "/Gate/",
  upload: "/uploadimg/",
  Scenery: "/Scenery/",
  Photos: "/SceneryPhotos/",
  Comment: "/Comment/",
  Invite: "/Invite/",
  RidingPlan: "/RidingPlan/",
  Ridinginfo: "/Ridinginfo/",
  Lvdao: "/Lvdao/",
  usermini: "/usermini/",
  users: "/user/",
  resetpassw: "/resetpassw/",
  lunbolist: "/lunbo/"
}