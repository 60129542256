import api from './axios.js'
import { apiList } from '@/api/api.js'

// 下面是简写的形式
// // getXXX 自定义的接口名字
// export const getXXX = (params) => api.get(`/apigb/v1/component`, { params})

// export const postXXX = (params) => api.post(`/apigb/v1/component/update-info`, params)

// export const postsave = (params) => api({
//   url: 'http://localhost/index.php/hourse/hourse_info/readuser', // 请求地址
//   method: 'get', // 请求方式
//   // data: params, // (一般post请求，我们习惯使用 data属性来传参)
//   params: params //(一般get请求，我们习惯使用params属性来传参）
// })


const alllist = {
  login: (params) => api({
    url: apiList.login, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  loginout: (params) => api({
    url: apiList.loginout, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),

  upload: (params) => api({
    url: apiList.upload, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  //入口管理
  getGatelist: (params) => api({
    url: apiList.Gate, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getGatelistbyid: (params, id) => api({
    url: apiList.Gate + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postGatelist: (params) => api({
    url: apiList.Gate, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updataGatelist: (params, id) => api({
    url: apiList.Gate + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deleteGatelist: (params, id) => api({
    url: apiList.Gate + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //绿道信息
  getLvdaolist: (params) => api({
    url: apiList.Lvdao, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getLvdaolistbyid: (params, id) => api({
    url: apiList.Lvdao + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postLvdaolist: (params) => api({
    url: apiList.Lvdao, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updataLvdaolist: (params, id) => api({
    url: apiList.Lvdao + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deleteLvdaolist: (params, id) => api({
    url: apiList.Lvdao + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //影集信息
  getScenerylist: (params) => api({
    url: apiList.Scenery, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getScenerylistbyid: (params, id) => api({
    url: apiList.Scenery + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postScenerylist: (params) => api({
    url: apiList.Scenery, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updataScenerylist: (params, id) => api({
    url: apiList.Scenery + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deleteScenerylist: (params, id) => api({
    url: apiList.Scenery + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //相册
  getPhotoslist: (params) => api({
    url: apiList.Photos, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  //评论
  getCommentlist: (params) => api({
    url: apiList.Comment, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getCommentbyid: (params, id) => api({
    url: apiList.Comment + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postCommentlist: (params) => api({
    url: apiList.Comment, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updataCommentlist: (params, id) => api({
    url: apiList.Comment + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  //邀请
  getInvitelist: (params) => api({
    url: apiList.Invite, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getInvitebyid: (params, id) => api({
    url: apiList.Invite + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postInvitelist: (params) => api({
    url: apiList.Invite, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updataInvitelist: (params, id) => api({
    url: apiList.Invite + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deleteInvitelist: (params, id) => api({
    url: apiList.Invite + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //计划
  getRidingPlanlist: (params) => api({
    url: apiList.RidingPlan, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getRidingPlanbyid: (params, id) => api({
    url: apiList.RidingPlan + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postRidingPlanlist: (params) => api({
    url: apiList.RidingPlan, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updataRidingPlanlist: (params, id) => api({
    url: apiList.RidingPlan + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deleteRidingPlanlist: (params, id) => api({
    url: apiList.RidingPlan + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //骑行信息
  getRidinginfolist: (params) => api({
    url: apiList.Ridinginfo, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getRidinginfobyid: (params, id) => api({
    url: apiList.Ridinginfo + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postRidinginfolist: (params) => api({
    url: apiList.Ridinginfo, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updataRidinginfolist: (params, id) => api({
    url: apiList.Ridinginfo + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deleteRidinginfolist: (params, id) => api({
    url: apiList.Ridinginfo + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //小程序用户
  getuserminilist: (params) => api({
    url: apiList.usermini, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getuserminibyid: (params, id) => api({
    url: apiList.usermini + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postuserminilist: (params) => api({
    url: apiList.usermini, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updatauserminilist: (params, id) => api({
    url: apiList.usermini + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deleteuserminilist: (params, id) => api({
    url: apiList.usermini + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //管理端用户
  getuserlist: (params) => api({
    url: apiList.users, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getuserbyid: (params, id) => api({
    url: apiList.users + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postuserlist: (params) => api({
    url: apiList.users, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updatauserlist: (params, id) => api({
    url: apiList.users + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deleteuserlist: (params, id) => api({
    url: apiList.users + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //修改密码
  postmypassw: (params) => api({
    url: apiList.resetpassw, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  //轮播图
  getlunbolist: (params) => api({
    url: apiList.lunbolist, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getlunbolistbyid: (params, id) => api({
    url: apiList.lunbolist + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postlunbolist: (params) => api({
    url: apiList.lunbolist, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updatalunbolist: (params, id) => api({
    url: apiList.lunbolist + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deletelunbolist: (params, id) => api({
    url: apiList.lunbolist + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
}

export default alllist