<template>
  <el-card class="order-container">
    <template #header>
      <div class="header">
        <el-input
          style="width: 200px; margin-right: 10px"
          :placeholder="'请输入' + searchtitle"
          v-model="searchinfo"
          @change="handleinput"
          clearable
        />
        <el-select
          @change="handleOption"
          v-model="selectinfo"
          style="width: 200px; margin-right: 10px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <!-- <el-button type="primary" size="small" icon="el-icon-edit">修改订单</el-button> -->
        <el-button type="primary" @click="handleConfig()">搜索</el-button>
        <!-- <el-button type="primary" :icon="HomeFilled" @click="handleSend()"
          >出库</el-button
        > -->
        <el-button type="danger" @click="handledeleteall()">删除</el-button>
        <!-- <el-button type="success" @click="handleAdd()">新增</el-button> -->
      </div>
    </template>
    <el-table
      v-loading="loading"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        :prop="item.propname"
        :label="item.name"
        v-for="item in labellist"
        :key="item.name"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="item.propname == 'imageurl'">
            <img style="height: 100px" :src="scope.row[item.propname]" alt="" />
          </div>
          <div v-else-if="item.propname == 'is_pass'">
            <div :class="scope.row.is_pass == 0 ? 'red' : 'green'">
              {{ scope.row.is_pass == 0 ? "未通过" : "通过" }}
            </div>
          </div>
          <div
            v-else
            style="height: 100px；  word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 超出几行省略 */
            overflow: hidden;
          "
          >
            {{ scope.row[item.propname] }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="totalPrice" label="订单总价"> </el-table-column>
      <el-table-column prop="orderStatus" label="订单状态"></el-table-column>
      <el-table-column prop="payType" label="支付方式"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"> </el-table-column> -->
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button
            :type="scope.row.is_pass == 0 ? 'success' : 'danger'"
            style="margin-left: 2px; width: 80px"
            @click="handleupgate(scope.row)"
            >{{ scope.row.is_pass == 0 ? "通过" : "不通过" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="currentPage"
      @current-change="changePage"
    />
  </el-card>
</template>

<script>
import alllist from "@/utils/request"
// import addoreditVue from '@/components/addorEdit.vue'
export default {
  components: {
    // addoreditVue
  },
  data () {
    return {
      loading: false,
      tableData: [], // 数据列表
      multipleSelection: [], // 选中项
      total: 0, // 总条数
      currentPage: 1, // 当前页
      pageSize: 10, // 分页大小
      searchinfo: '', // 搜索内容
      searchtitle: '说明', //搜索框搜索内容
      selectinfo: '', // 下拉框内容
      // 筛选项默认值
      getdatainfo: 'articlelist',
      deletearr: [],
      labellist: [
        {
          propname: "id",
          name: 'ID'
        },
        {
          propname: "longitude",
          name: '经度'
        },
        {
          propname: "latitude",
          name: '纬度'
        },
        {
          propname: "imageurl",
          name: '图片'
        },
        {
          propname: "bynickname",
          name: '上传人昵称'
        },
        {
          propname: "byopenid",
          name: '上传人id'
        },
        {
          propname: "notes",
          name: '说明'
        },
        {
          propname: "is_pass",
          name: '是否通过'
        },
        {
          propname: "passbyname",
          name: '审核人'
        },
        {
          propname: "creat_time",
          name: '时间'
        }
      ],
      options: [{
        value: '',
        label: '全部'
      }, {
        value: 0,
        label: '未通过'
      }, {
        value: 1,
        label: '已通过'
      }],
    }
  },
  created () {
    this.statusnum = 0
  },
  mounted () {
    // this.getList()
  },
  methods: {
    // 获取列表方法
    getList () {
      this.loading = true
      let data = {
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        searchinfo: this.searchinfo,
        selectinfo: this.selectinfo
      }
      alllist.getGatelist(data).then((res) => {
        console.log(res)
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      })
    },
    handleAdd () {
      this.$router.push({
        path: '/addoredit'
      })
    },
    changestatus () {
      this.getList()
    },
    // 触发过滤项方法
    handleinput () {
      this.currentPage = 1
      // getOrderList()
      this.getList()
    },
    // checkbox 选择项
    handleOption (val) {
      console.log(val)
      this.selectinfo = val
      this.getList()
    },
    // checkbox 选择项
    handleSelectionChange (val) {
      let arr = []
      for (var i = 0; i < val.length; i++) {
        arr.push(val[i].id)
      }
      this.deletearr = arr
    },
    // 翻页方法
    changePage (val) {
      console.log(val)
      this.currentPage = val
      this.getList()
    },
    handleedit (row) {
      console.log('row', row)
      this.$router.push({
        path: '/addoredit?id=' + row.id
      })
    },
    handleupgate (row) {
      let data = row
      if (data.is_pass == 0) {
        data.is_pass = 1
      } else {
        data.is_pass = 0
      }
      alllist.updataGatelist(data, data.id).then((res) => {
        console.log(res)
        this.$message({
          type: 'success',
          message: data.is_admin == 0 ? '取消成功！' : '操作成功！'
        })
      })
    },
    handledeleteall () {
      if (this.deletearr.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择！'
        })
      } else {
        this.$alert('确认要删除吗？', '删除', {
          confirmButtonText: '确定',
          callback: action => {
            if (action == 'confirm') {
              for (var i = 0; i < this.deletearr.length; i++) {
                let data = {}
                alllist.deleteGatelist(data, this.deletearr[i]).then(() => {
                  this.getList()
                })
              }
              this.$message({
                type: 'success',
                message: '删除成功！'
              })
            }
          }
        })
      }
    },
    handledelete (row) {
      this.$alert('确认要删除吗？', '删除', {
        confirmButtonText: '确定',
        callback: action => {
          if (action == 'confirm') {
            let data = {}
            alllist.deleteGatelist(data, row.id).then(() => {
              this.$message({
                type: 'success',
                message: '删除成功！'
              })
              this.getList()
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="less">
</style>