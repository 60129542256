import Vue from 'vue'
import VueRouter from 'vue-router'
import { localGet } from '../utils/info'

// // 0. 如果使用模块化机制编程，导入Vue和VueRouter，要调用 Vue.use(VueRouter)
Vue.use(VueRouter)

import gatePage from "../views/gatePage.vue"
import login from "../login/loginPage.vue"
import Lvdao from "../views/lvdaoPage.vue"
import Scenery from "../views/sceneryPage.vue"
import Comment from "../views/commentPage.vue"
import Invite from "../views/invitePage.vue"
import RidingPlan from "../views/ridingPlan.vue"
import usermini from "../views/userminiPage.vue"
import users from "../views/usersPage.vue"
import resetpassw from "../views/resetPassword.vue"
import Ridinginfo from "../views/ridingInfo.vue"
import lunboPage from "../views/lunboPage.vue"

export const routes = [
  { path: '/', name: "product", component: Lvdao },
  { path: '/Gate', name: "Gate", component: gatePage },
  { path: '/login', name: "login", component: login },
  { path: '/product', name: "product", component: Lvdao },
  { path: '/Scenery', name: "Scenery", component: Scenery },
  { path: '/Comment', name: "Comment", component: Comment },
  { path: '/Invite', name: "Invite", component: Invite },
  { path: '/RidingPlan', name: "RidingPlan", component: RidingPlan },
  { path: '/Ridinginfo', name: "Ridinginfo", component: Ridinginfo },
  { path: '/usermini', name: "usermini", component: usermini },
  { path: '/users', name: "users", component: users },
  { path: '/template', name: "template", component: lunboPage },
  { path: '/resetpassw', name: "resetpassw", component: resetpassw }
]

// // 3. 创建 router 实例，然后传 `routes` 配置

const router = new VueRouter({
  mode: "history",
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    // 如果路径是 /login 则正常执行
    console.log("to", from)
    next()
  } else {
    console.log(from)
    // 如果不是 /login，判断是否有 token
    if (!localGet('token')) {
      // 如果没有，则跳至登录页面
      next({ path: '/login' })
    } else {
      // 否则继续执行
      next()
    }
  }
})
export default router 
